const vaccinatedKeyword = 'vaccinated'
const unvaccinatedKeyword = 'unvaccinated'

export function encodeRestrictionDestinationUrl(
  originCountry: string,
  destinationCountry: string,
  vaccinated: boolean,
): string {
  return `/travel-restrictions/${destinationCountry}-from-${originCountry}-${
    vaccinated ? vaccinatedKeyword : unvaccinatedKeyword
  }`
}

export function decodeRestrictionDestinationUrl(url: string): {
  originCountry: string
  destinationCountry: string
  vaccinated: boolean
} {
  const [, originCountry, destinationCountry, vaccinated] = url.match(
    /\/travel-restrictions\/(.+)-from-(.+)-(vaccinated|unvaccinated)/,
  )
  return { originCountry, destinationCountry, vaccinated: vaccinated === vaccinatedKeyword }
}

export function encodeRestrictionOriginUrl(originCountry: string, vaccinated: boolean): string {
  return `/travel-restrictions/${originCountry}-${
    vaccinated ? vaccinatedKeyword : unvaccinatedKeyword
  }`
}

export function encodeVisasOriginUrl(originCountry: string): string {
  return `/visas/${originCountry}`
}

export function decodeRestrictionOriginUrl(url: string): {
  originCountry: string
  vaccinated: boolean
} {
  const [, originCountry, vaccinated] = url.match(
    /\/travel-restrictions\/(.+)-(vaccinated|unvaccinated)/,
  )
  return { originCountry, vaccinated: vaccinated === vaccinatedKeyword }
}

export function encodeDemesticRestrictionOriginUrl(
  originCountry: string,
  originRegion: string,
  vaccinated: boolean,
): string {
  return `/travel-restrictions/${originCountry}/${originRegion}-${
    vaccinated ? vaccinatedKeyword : unvaccinatedKeyword
  }`
}

export function encodeDemesticRestrictionDestinationUrl(
  originCountry: string,
  originRegion: string,
  destinationRegion: string,
  vaccinated: boolean,
): string {
  return `/travel-restrictions/${originCountry}/${destinationRegion}-from-${originRegion}-${
    vaccinated ? vaccinatedKeyword : unvaccinatedKeyword
  }`
}
